import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["project", "categoryList", "categoryListItem", "trigger"];

  activeCategory = null;

  activeClass = "active";

  hiddenClass = "hidden";

  noAnimateClass = "no-animate";

  connect() {
    this.active_indicator = document.createElement("div");
    this.active_indicator.setAttribute("class", "active-indicator");

    if (this.categoryListTarget) {
      this.categoryListTarget.appendChild(this.active_indicator);
    }

    this.updateActiveNavIndicator(this.triggerTargets[0]);
  }

  setFilter(evt) {
    const button = evt.currentTarget;
    this.activeCategory = button.dataset.category;
    this.updateActiveNavItem(button);
    this.filterProjects();
  }

  updateActiveNavItem(button) {
    this.categoryListItemTargets
      .filter(item => item.classList.contains(this.activeClass))
      .forEach(item => item.classList.remove(this.activeClass));
    button.closest("li").classList.add(this.activeClass);
    this.updateActiveNavIndicator(button);
  }

  updateActiveNavIndicator(button) {
    this.active_indicator.style.left = `${button.offsetLeft}px`;
    this.active_indicator.style.width = `${button.offsetWidth}px`;
  }

  filterProjects() {
    let index = 0;

    const toggle = (project, direction) => {
      project.classList[direction](this.hiddenClass);
      project.style.transitionDelay = `${index * 25}ms`;
      if (direction === "add") {
        project.setAttribute("tabindex", -1);
      } else {
        project.removeAttribute("tabindex");
      }
      index += 1;
    };

    this.projectTargets.forEach(project => {
      if (
        project.classList.contains(this.hiddenClass) &&
        (this.activeCategory === project.dataset.category ||
          !this.activeCategory)
      ) {
        toggle(project, "remove");
      } else if (
        !project.classList.contains(this.hiddenClass) &&
        (this.activeCategory !== project.dataset.category &&
          this.activeCategory)
      ) {
        toggle(project, "add");
      } else {
        project.style.transitionDelay = "initial";
      }
    });
  }
}
