import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"];

  visibleClass = "visible";

  connect() {
    window.setTimeout(this.staggerEntrances.bind(this), 700);
  }

  staggerEntrances() {
    this.itemTargets.forEach((item, index) => {
      item.style.transitionDelay = `${index * 45}ms`;
      item.classList.add(this.visibleClass);
    });
  }
}
