import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["trigger", "body"];

  overlayOpenClass = "overlay-open";

  toggle(evt) {
    const trigger = evt.currentTarget;
    const target = document.querySelector(trigger.dataset.targetElement);
    if (!target) {
      return;
    }
    if (target.hidden) {
      target.hidden = false;
      this.bodyTarget.classList.add(this.overlayOpenClass);
    } else {
      target.hidden = true;
      this.bodyTarget.classList.remove(this.overlayOpenClass);
    }
  }

  close(evt) {
    if (evt.target.closest("a")) {
      return;
    }
    evt.currentTarget.hidden = true;
    this.bodyTarget.classList.remove(this.overlayOpenClass);
  }
}
