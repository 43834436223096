import { Controller } from "stimulus";

export default class extends Controller {
  highlightImageClass = "highlight-image";

  visibleClass = "visible";

  toFrontClass = "to-front";

  fadeOutClass = "fade-out";

  overlayOpenClass = "overlay-open";

  connect() {
    if (this.isTouchDevice()) {
      return;
    }
    this.body = document.querySelector("body");
    this.createHighlightFigure();
  }

  isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints;
  }

  createHighlightFigure() {
    if (this.highlightFigure) {
      return;
    }
    this.highlightFigure = document.createElement("figure");
    this.highlightFigure.classList.add(this.highlightImageClass);
    this.body.appendChild(this.highlightFigure);
  }

  followCursor(evt) {
    if (!this.highlightImage) {
      return;
    }
    if (evt.pageX < window.innerWidth / 2) {
      this.highlightImage.style.left = `${evt.pageX + 16}px`;
    } else {
      this.highlightImage.style.left = `${evt.pageX -
        this.highlightImage.offsetWidth -
        16}px`;
    }
    this.highlightImage.style.top = `${evt.pageY -
      this.highlightImage.offsetHeight -
      16}px`;
  }

  show(evt) {
    if (
      !this.highlightFigure ||
      this.body.classList.contains(this.overlayOpenClass)
    ) {
      return;
    }
    const trigger = evt.currentTarget;
    trigger.classList.add(this.toFrontClass);

    const handleImageLoad = loadEvt => {
      loadEvt.target.classList.add(this.visibleClass);
    };

    if (this.hideTimer) {
      window.clearTimeout(this.hideTimer);
    }

    const highlightImage = document.createElement("img");
    highlightImage.src = trigger.dataset.imageUrlMedium;
    highlightImage.alt = trigger.textContent;
    highlightImage.addEventListener("load", handleImageLoad.bind(this));

    this.highlightFigure.appendChild(highlightImage);
    this.highlightFigure.classList.add(this.visibleClass);
    window.setTimeout(() => {
      this.highlightFigure.classList.add(this.toFrontClass);
      this.killPreviousImages();
    });
  }

  hide(evt) {
    if (!this.highlightFigure) {
      return;
    }
    const trigger = evt.currentTarget;
    trigger.classList.remove(this.toFrontClass);

    const highlightImages = this.highlightFigure.querySelectorAll(
      `img[src="${trigger.dataset.imageUrlMedium}"]:not(.${this.fadeOutClass})`
    );

    if (!highlightImages.length) {
      return;
    }

    highlightImages.forEach(img => {
      img.classList.add(this.fadeOutClass);
      window.setTimeout(() => {
        if (!img.parentNode) {
          return;
        }
        img.parentNode.removeChild(img);
        if (this.highlightFigure.matches(":empty")) {
          this.highlightFigure.classList.remove(this.toFrontClass);
          this.hideTimer = window.setTimeout(() => {
            this.highlightFigure.classList.remove(this.visibleClass);
            this.hideTimer = null;
          }, 200);
        }
      }, 160);
    });
  }

  killPreviousImages() {
    this.highlightFigure
      .querySelectorAll("img:not(:last-child)")
      .forEach(img => {
        img.classList.add(this.fadeOutClass);
        window.setTimeout(() => {
          if (img.parentNode) {
            img.parentNode.removeChild(img);
          }
        }, 160);
      });
  }
}
